import Repository from "./base/Repository";

interface INewSquad {
  id?: number;
  description: string;
  leader_id: number;
  image?: File | null;
  status: string;
}
interface INewSquadStatus {
  status: string;
}
export default class SquadsRepository extends Repository {
  async getAll() {
    return await this.datasource.get(`/api/squads`);
  }

  async getById(id: number | string) {
    return await this.datasource.get(`/api/squads/${id}`);
  }

  async create(data: INewSquad) {
    return await this.datasource.upload(`/api/squads`, data);
  }

  async update(id: number | string, data: INewSquad) {
    return await this.datasource.uploadPatch(`/api/squads/${id}`, data);
  }

  async updateStatus(id: number | string, data: INewSquadStatus) {
    return await this.datasource.updatePatch(
      `/api/squads/${id}/update-status`,
      data
    );
  }
}
