
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class DashboardView extends Vue {
  src = "";
  mounted() {
    if (localStorage.token && localStorage.token != "") {
      if (
        localStorage.userTypeDashboard &&
        localStorage.userTypeDashboard != "" &&
        localStorage.userTypeDashboard != "null"
      ) {
        this.src = localStorage.userTypeDashboard;
      }
    }
  }
}
