
import Vue from "vue";
import Component from "vue-class-component";
import { Location } from "vue-router";

import JobsRepository from "@/repositories/JobsRepository";
import IJobs from "@/domain/interfaces/IJobs";

@Component
export default class EmploymentOpportunitiesView extends Vue {
  pageTitle = "Vagas";
  pageIcon = "mdi-briefcase-outline";
  pageSubtitle = "Liste e cadastre as vagas aqui";
  isNoDataText = "Não há vagas cadastradas";
  listRoute = "jobs";
  registerRoute = "jobsRegister";
  editRoute = "jobsEdit";
  filterTerm = "";
  filterStatus = "";
  statusItems = [
    { text: "Todos os status", value: "" },
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];
  loadingData = false;
  isLoadingStatus = false;
  readonly JobsRepository: JobsRepository = new JobsRepository();
  headers = [
    {
      text: "Status",
      value: "status",
      sortable: false,
      width: "5%",
      align: "start",
    },
    {
      text: "Última modificação",
      value: "created_at",
      sortable: false,
      width: "20%",
      align: "center",
    },
    {
      text: "Título",
      value: "title",
      sortable: false,
      width: "70%",
      align: "start",
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      width: "5%",
      align: "end",
    },
  ];
  jobs: Array<IJobs> = [];

  mounted() {
    this.getAll();
    this.getPageInfo();
  }

  getPageInfo() {
    if (localStorage.menus != "" && localStorage.menus != "null") {
      var getMenus = JSON.parse(localStorage.menus);
      var getRouteName = this.$route.name;
      var getMenuSelectedByRoute = getMenus.filter(function (value) {
        return value.route === getRouteName;
      });
      if (
        getMenuSelectedByRoute[0].description != "" &&
        getMenuSelectedByRoute[0].description
      ) {
        this.pageTitle = getMenuSelectedByRoute[0].description;
      }
      if (
        getMenuSelectedByRoute[0].icon != "" &&
        getMenuSelectedByRoute[0].icon
      ) {
        this.pageIcon = getMenuSelectedByRoute[0].icon;
      }
      if (
        getMenuSelectedByRoute[0].observation != "" &&
        getMenuSelectedByRoute[0].observation
      ) {
        this.pageSubtitle = getMenuSelectedByRoute[0].observation;
      }
    }
  }

  async getAll() {
    try {
      this.loadingData = true;
      const response = await this.JobsRepository.getAll();
      this.jobs = JSON.parse(response.data).jobs.data;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  get filteredData() {
    return this.jobs.filter(this.filterByTerm).filter(this.filterByStatus);
  }

  filterByTerm(jobs: IJobs): boolean {
    const filterTerm = this.filterTerm.toLowerCase();
    return filterTerm != ""
      ? jobs.title.toLowerCase().includes(filterTerm) ||
          jobs.description.toLowerCase().includes(filterTerm)
      : true;
  }

  filterByStatus(jobs: IJobs): boolean {
    const status = this.filterStatus;
    return status != ""
      ? (jobs.status === "A") == (this.filterStatus === "A")
      : true;
  }

  async changeStatus(jobsId: string, status: boolean) {
    try {
      this.loadingData = true;
      this.isLoadingStatus = true;
      var statusData = {
        status: "",
      };
      if (status === true) {
        statusData.status = "A";
      } else {
        statusData.status = "I";
      }
      const responseStatus = await this.JobsRepository.updateStatus(
        jobsId,
        statusData
      );
      if (responseStatus.status === 200 || responseStatus.status === 201) {
        this.$notification.success("Status alterado com sucesso!");
      }
    } catch (error: any) {
      console.error(JSON.parse(error.response.data));
    } finally {
      this.loadingData = false;
      this.isLoadingStatus = false;
    }
  }

  getDatetime(jobs: IJobs) {
    if (jobs.updated_at) {
      const date = jobs.updated_at
        .substring(0, 10)
        .split("-")
        .reverse()
        .join("/");
      const hour = jobs.updated_at.substring(11, 16);
      return `${date} ${hour}`;
    } else {
      const date = jobs.created_at
        .substring(0, 10)
        .split("-")
        .reverse()
        .join("/");
      const hour = jobs.created_at.substring(11, 16);
      return `${date} ${hour}`;
    }
  }

  editItem(jobId: string) {
    const params: Location = {
      name: this.editRoute,
      params: {
        id: jobId,
      },
    };
    this.$router.push(params);
  }
}
