
import Vue from "vue";
import Component from "vue-class-component";

import UserTypesRepository from "@/repositories/UserTypesRepository";
import MenusRepository from "@/repositories/MenusRepository";
import IUserTypes from "@/domain/interfaces/IUserTypes";
import IMenus from "@/domain/interfaces/IMenus";
import AuthRepository from "@/repositories/AuthRepository";

interface IMenusSelected {
  id: number;
  manage: string;
}

@Component
export default class UserTypesRegister extends Vue {
  readonly UserTypesRepository: UserTypesRepository = new UserTypesRepository();
  readonly AuthRepository: AuthRepository = new AuthRepository();
  readonly MenusRepository: MenusRepository = new MenusRepository();
  menus: Array<IMenus> = [];
  menusSelected: Array<IMenusSelected> = [];
  pageTitle = "";
  listRoute = "user-types";
  registerRoute = "userTypesRegister";
  editRoute = "userTypesEdit";
  newUserType: IUserTypes = {} as IUserTypes;
  userTypes: Array<IUserTypes> = [];
  statusItems = [
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];
  rules = {
    required: [(value: string) => !!value || "Campo obrigatório."],
  };
  loadingData = false;
  loadingSave = false;
  isCurrentUser = false;

  async mounted() {
    await this.loadMenus();
    if (this.$route.params.id) {
      if (localStorage.userTypeId == this.$route.params.id) {
        this.isCurrentUser = true;
        this.newUserType.status = "A";
      }
      this.pageTitle = "Editar Tipo de Usuário";
      this.loadById(this.$route.params.id);
    } else {
      this.pageTitle = "Cadastrar Tipo de Usuário";
      this.newUserType.status = "A";
    }
  }

  async loadById(id: string | number) {
    try {
      this.loadingData = true;
      const response = await this.UserTypesRepository.getById(id);
      this.newUserType = JSON.parse(response.data).userType;
      var newMenusSelectedList: Array<IMenusSelected> = [];
      var newMenusList = this.menus;
      this.newUserType.menus.map(function (menusUserType: IMenus) {
        if (menusUserType.status === "A") {
          var menuSelected = {
            id: menusUserType.pivot.menu_id,
            manage: menusUserType.pivot.manage,
          };
          newMenusSelectedList.push(menuSelected);
          newMenusList.map(function (menus: any) {
            if (menus.id == menusUserType.pivot.menu_id) {
              var manage = menusUserType.pivot.manage;
              menus.permissions = manage.toString();
            }
          });
        }
      });
      this.menus = newMenusList;
      this.menusSelected = newMenusSelectedList;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  async saveData() {
    this.renderMenusSelected();
    if (this.menusSelected.length == 0) {
      this.$notification.error("Os menus não podem ficar vazios!");
    } else {
      if (this.validateForm()) {
        var data = {
          description: this.newUserType.description,
          dashboard: this.newUserType.dashboard,
          status: this.newUserType.status,
          menus: this.menusSelected,
        };
        var statusData = {
          status: this.newUserType.status,
        };

        try {
          this.loadingSave = true;
          if (this.$route.params.id) {
            const response = await this.UserTypesRepository.update(
              this.$route.params.id,
              data
            );
            const responseStatus = await this.UserTypesRepository.updateStatus(
              this.$route.params.id,
              statusData
            );
            if (localStorage.userTypeId == this.$route.params.id) {
              const currentUser = await this.AuthRepository.getCurrentUser();
              localStorage.menus = JSON.stringify(
                JSON.parse(currentUser.data).user.type.menus
              );
            }
            if (
              (response.status === 200 || response.status === 201) &&
              (responseStatus.status === 200 || responseStatus.status === 201)
            ) {
              this.$notification.success(
                "Tipo de Usuário editado com sucesso!"
              );
              this.$router.push({ name: "user-types" });
            }
          } else {
            const response = await this.UserTypesRepository.create(data);
            if (response.status === 200 || response.status === 201) {
              this.$notification.success("Tipo de Usuário criado com sucesso!");
              this.$router.push({ name: "user-types" });
            }
          }
          // eslint-disable-next-line
        } catch (error: any) {
          console.error(error);
          var message = JSON.parse(error.response.data).errors;
          if (message.dashboard) {
            this.$notification.error(message.dashboard[0]);
          } else if (message.description) {
            this.$notification.error(message.description[0]);
          } else {
            this.$notification.error(message);
          }
        } finally {
          this.loadingSave = false;
        }
      }
    }
  }

  renderMenusSelected() {
    var newMenuslist = [];
    this.menus.map(function (value: IMenus) {
      if (value.permissions != "") {
        var menu = {
          id: value.id,
          manage: value.permissions,
        };
        newMenuslist.push(menu);
      }
    });
    this.menusSelected = newMenuslist;
  }

  async loadMenus() {
    const response = await this.MenusRepository.getAll();
    this.menus = this.filterByActiveStatus(
      JSON.parse(response.data).menus.data
    );
    this.menus.map(function (value, key) {
      value.permissions = "";
    });
  }

  filterByActiveStatus(item: Array<IMenus>) {
    return item.filter(function (value) {
      return value.status === "A";
    });
  }

  validateForm() {
    return (
      this.$refs.registerUserTypeForm as Vue & { validate: () => boolean }
    ).validate();
  }
}
