
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Location } from "vue-router";

interface ITabs {
  id: number;
  description: string;
  icon: string;
  route: string;
  status: string;
  created_at: string;
  updated_at: string;
  pivot: {
    menu_id: number;
    user_type_id: number;
  };
}

@Component({})
export default class CustomTabsApp extends Vue {
  @Prop(Boolean) showTabs!: boolean;
  @Prop(Array) items!: Array<ITabs>;
  tabs = "";
}
