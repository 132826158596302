import Repository from "./base/Repository";

interface IJobsCandidatesFilter {
  candidate_name: string;
  salary_expectation_initial: number | null | string;
  salary_expectation_final: number | null | string;
  job_id: number | null | string;
  job_title: string;
  department_id: Array<number>;
  order_direction: string;
  order_by: string;
  page: number | null;
  limit: number | null;
}

interface IJobsCandidatesStatus {
  status: string;
}

export default class JobsCandidatesRespository extends Repository {
  async getAll(data: IJobsCandidatesFilter) {
    return await this.datasource.get(`/api/jobs-candidates`, { params: data });
  }

  async updateStatus(id: number | string, data: IJobsCandidatesStatus) {
    return await this.datasource.updatePatch(
      `/api/jobs-candidates/${id}/update-status`,
      data
    );
  }
}
