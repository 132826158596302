
import Vue from "vue";
import Component from "vue-class-component";
import JobsCandidatesRespository from "@/repositories/JobsCandidatesRepository";
import JobsRepository from "@/repositories/JobsRepository";
import IJobs from "@/domain/interfaces/IJobs";
import DepartmentsRepository from "@/repositories/DepartmentsRepository";
import IDepartments from "@/domain/interfaces/IDepartments";

interface IJobsCandidates {
  created_at: string;
  curriculum_path: string;
  email: string;
  id: number;
  job: any;
  job_id: number;
  name: string;
  observation: string | null;
  phone: string;
  salary_expectation: string;
  status: string;
  updated_at: string;
  url_linkedin: string;
}

@Component
export default class JobsCandidatesView extends Vue {
  pageTitle = "Candidaturas";
  pageIcon = "mdi-briefcase-outline";
  pageSubtitle = "Liste e acesse as últimas candidaturas";
  listRoute = "jobs-candidates";
  isNoData = false;
  loadingData = false;
  loadingDataJobs = false;
  loadingDataDepartments = false;
  isLoadingStatus = false;
  filtersAppearence = true;
  readonly JobsCandidatesRespository: JobsCandidatesRespository =
    new JobsCandidatesRespository();
  readonly JobsRepository: JobsRepository = new JobsRepository();
  readonly DepartmentsRepository: DepartmentsRepository =
    new DepartmentsRepository();
  jobsCandidates: Array<IJobsCandidates> = [];
  jobsCandidatesPage = {
    current_page: 1,
    from: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  };
  jobsItems: Array<IJobs> = [];
  departmentsItems: Array<IDepartments> = [];
  valueInitialDate = "";
  valueFinalDate = "";
  menuInitialDate = false;
  menuFinalDate = false;
  search = {
    candidate_name: "",
    initial_date: "",
    status: "A",
    final_date: "",
    salary_expectation_initial: this.valueInitialDate,
    salary_expectation_final: this.valueFinalDate,
    job_id: "",
    job_title: "",
    department_id: [],
    order_direction: "desc",
    order_by: "created_at",
    page: 1,
    limit: 15,
  };
  orderDirectionItems = [
    {
      value: "asc",
      text: "Crescente",
    },
    {
      value: "desc",
      text: "Decrescente",
    },
  ];
  orderByItems = [
    {
      value: "created_at",
      text: "Data",
    },
    {
      value: "candidate_name",
      text: "Nome do Candidato",
    },
    {
      value: "salary_expectation",
      text: "Expectativa Salarial",
    },
    {
      value: "job_title",
      text: "Nome da Vaga",
    },
    {
      value: "email",
      text: "Email do Candidato",
    },
    {
      value: "phone",
      text: "Telefone",
    },
  ];
  filterStatus = "";
  statusItems = [
    { text: "Todos os status", value: "" },
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];

  mounted() {
    this.loadData();
    this.loadJobs();
    this.loadDepartments();
    this.getPageInfo();
  }

  getPageInfo() {
    if (localStorage.menus != "" && localStorage.menus != "null") {
      var getMenus = JSON.parse(localStorage.menus);
      var getRouteName = this.$route.name;
      var getMenuSelectedByRoute = getMenus.filter(function (value) {
        return value.route === getRouteName;
      });
      if (
        getMenuSelectedByRoute[0].description != "" &&
        getMenuSelectedByRoute[0].description
      ) {
        this.pageTitle = getMenuSelectedByRoute[0].description;
      }
      if (
        getMenuSelectedByRoute[0].icon != "" &&
        getMenuSelectedByRoute[0].icon
      ) {
        this.pageIcon = getMenuSelectedByRoute[0].icon;
      }
      if (
        getMenuSelectedByRoute[0].observation != "" &&
        getMenuSelectedByRoute[0].observation
      ) {
        this.pageSubtitle = getMenuSelectedByRoute[0].observation;
      }
    }
  }

  async loadData() {
    try {
      this.loadingData = true;
      const data = {
        candidate_name: this.search.candidate_name,
        salary_expectation_initial: this.formatCurrencyData(
          this.search.salary_expectation_initial
        ),
        salary_expectation_final: this.formatCurrencyData(
          this.search.salary_expectation_final
        ),
        status: this.search.status,
        job_id: this.search.job_id,
        job_title: this.search.job_title,
        department_id: this.search.department_id,
        order_direction: this.search.order_direction,
        order_by: this.search.order_by,
        initial_date: this.search.initial_date,
        final_date: this.search.final_date,
        page: this.search.page,
        limit: this.search.limit,
      };
      const response = await this.JobsCandidatesRespository.getAll(data);
      this.jobsCandidatesPage = {
        current_page: JSON.parse(response.data).candidates.current_page,
        from: JSON.parse(response.data).candidates.from,
        last_page: JSON.parse(response.data).candidates.last_page,
        per_page: JSON.parse(response.data).candidates.per_page,
        total: JSON.parse(response.data).candidates.total,
      };
      this.jobsCandidates = JSON.parse(response.data).candidates.data;
      if (this.jobsCandidates) {
        if (this.jobsCandidates.length === 0) {
          this.isNoData = true;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  async loadJobs() {
    try {
      this.loadingDataJobs = true;
      const response = await this.JobsRepository.getAll();
      const responseFiltered = this.filterByActiveStatus(
        JSON.parse(response.data).jobs.data
      );
      var newJobItem = [
        {
          id: "",
          title: "Todas as Vagas",
          status: "A",
        },
      ];

      var jobsData = newJobItem.concat(responseFiltered);
      this.jobsItems = jobsData;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingDataJobs = false;
    }
  }

  async loadDepartments() {
    try {
      this.loadingDataDepartments = true;
      const response = await this.DepartmentsRepository.getAll();
      this.departmentsItems = this.filterByActiveStatus(
        JSON.parse(response.data).departments.data
      );
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingDataDepartments = false;
    }
  }

  async changeStatus(id: number | string, status: boolean) {
    try {
      this.loadingData = true;
      this.isLoadingStatus = true;
      var statusData = {
        status: "",
      };
      if (status === true) {
        statusData.status = "A";
      } else {
        statusData.status = "I";
      }
      const responseStatus = await this.JobsCandidatesRespository.updateStatus(
        id,
        statusData
      );
      if (responseStatus.status === 200 || responseStatus.status === 201) {
        this.$notification.success("Status alterado com sucesso!");
      }
    } catch (error: any) {
      console.error(JSON.parse(error.response.data));
    } finally {
      this.loadingData = false;
      this.isLoadingStatus = false;
    }
  }

  get formatInitialSalary() {
    const onlyNumbers = /[0-9/]+/;
    var value = this.search.salary_expectation_initial;
    if (onlyNumbers.test(this.search.salary_expectation_initial)) {
      value = value.replace(".", "").replace(",", "").replace(/\D/g, "");

      const options = { minimumFractionDigits: 2 };
      const result = new Intl.NumberFormat("pt-BR", options).format(
        parseFloat(value) / 100
      );
      this.search.salary_expectation_initial = result.replace(/\D,./g, "");
      return result;
    } else {
      value = value.replace(/\D/g, "");
      this.search.salary_expectation_initial = "0,00".replace(/\D,./g, "");
      return value;
    }
  }

  get formatFinalSalary() {
    const onlyNumbers = /[0-9/]+/;
    var value = this.search.salary_expectation_final;
    if (onlyNumbers.test(this.search.salary_expectation_final)) {
      value = value.replace(".", "").replace(",", "").replace(/\D/g, "");

      const options = { minimumFractionDigits: 2 };
      const result = new Intl.NumberFormat("pt-BR", options).format(
        parseFloat(value) / 100
      );
      this.search.salary_expectation_final = result.replace(/\D,./g, "");
      return result;
    } else {
      value = value.replace(/\D/g, "");
      this.search.salary_expectation_final = "0,00".replace(/\D,./g, "");
      return value;
    }
  }

  isLetter(e) {
    let char = String.fromCharCode(e.keyCode);
    if (/^[A-Za-záàâãäéèêëíïóôõöúùüçñÁÀÂÃÄÉÈËÍÏÓÔÕÖÚÙÜÇÑ\s]+$/.test(char)) {
      e.preventDefault();
    }
  }

  public get selectedInitialDate() {
    if (this.search.initial_date)
      return this.formateDate(`${this.search.initial_date}`);
    return this.search.initial_date;
  }

  public set selectedInitialDate(set: string) {
    this.search.initial_date = set;
  }

  public get selectedFinalDate() {
    if (this.search.final_date)
      return this.formateDate(`${this.search.final_date}`);
    return this.search.final_date;
  }

  public set selectedFinalDate(set: string) {
    this.search.final_date = set;
  }

  formateDate(date: string) {
    var dateFormatted = date.split("-");
    return `${dateFormatted[2]}/${dateFormatted[1]}/${dateFormatted[0]}`;
  }

  notAllowedToWrite(e) {
    e.preventDefault();
  }

  formatCurrencyData(value: string) {
    var withoutDots = value.split(".").join("");
    var replaceCommaToDots = parseFloat(withoutDots.replace(",", "."));
    if (replaceCommaToDots != 0) {
      return replaceCommaToDots.toString();
    } else {
      return "";
    }
  }

  formatDatetime(datetime: string) {
    if (datetime) {
      const date = datetime.substring(0, 10).split("-").reverse().join("/");
      const hour = datetime.substring(11, 16);
      return `${date} ${hour}`;
    }
  }

  formatPhone(phone: string) {
    if (phone) {
      if (phone.length == 11) {
        return `(${phone[0]}${phone[1]}) ${phone[2]} ${phone[3]}${phone[4]}${phone[5]}${phone[6]}-${phone[7]}${phone[8]}${phone[9]}${phone[10]}`;
      } else if (phone.length == 10) {
        return `(${phone[0]}${phone[1]}) ${phone[2]}${phone[3]}${phone[4]}${phone[5]}-${phone[6]}${phone[7]}${phone[8]}${phone[9]}`;
      } else {
        return phone;
      }
    }
  }

  formatSalaryExpectation(salary: string) {
    if (salary) {
      if (salary === "0.00" || salary === "0" || salary === "") {
        return "Não informado";
      } else {
        var salaryFormated = salary.replace(".", ",");
        return `R$ ${salaryFormated}`;
      }
    }
  }

  formatJobTitle(job: any) {
    if (job) {
      if (job.title) {
        return job.title;
      }
      return "Não informado";
    } else {
      return "Não informado";
    }
  }

  formatObservation(item: any) {
    if (item.observation) {
      return item.observation;
    } else {
      return "Não informado";
    }
  }

  formatLastPage(page: number) {
    if (page > 1) {
      return `${page} Páginas`;
    } else {
      return `1 Página`;
    }
  }

  getTextStatus(status: string) {
    if (status) {
      if (status === "A") {
        return "Ativo";
      } else if (status === "I") {
        return "Inativo";
      } else {
        return;
      }
    }
  }

  getColorStatus(status: string) {
    if (status) {
      if (status === "A") {
        return "success";
      } else if (status === "I") {
        return "default";
      } else {
        return;
      }
    }
  }

  goToUrl(url: string) {
    if (url) {
      var link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.click();
      return true;
    }
  }

  filterByActiveStatus(item: Array<any>) {
    return item.filter(function (value) {
      return value.status === "A";
    });
  }

  filtersAppearenceIcon() {
    if (this.filtersAppearence) {
      return "mdi-chevron-up";
    } else {
      return "mdi-chevron-down";
    }
  }

  goToPreviousPage() {
    if (this.jobsCandidatesPage.current_page > 1) {
      this.search.page = this.search.page - 1;
      this.loadData();
    }
  }

  goToNextpage() {
    if (
      this.jobsCandidatesPage.current_page < this.jobsCandidatesPage.last_page
    ) {
      this.search.page = this.search.page + 1;
      this.loadData();
    }
  }

  cleanFilters() {
    this.search.candidate_name = "";
    this.search.initial_date = "";
    this.search.final_date = "";
    this.search.salary_expectation_final = "";
    this.search.salary_expectation_initial = "";
    this.search.job_id = "";
    this.search.job_title = "";
    this.search.department_id = [];
    this.search.order_direction = "desc";
    this.search.order_by = "created_at";
    this.search.status = "A";
  }
}
