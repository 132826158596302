import Repository from "./base/Repository";

interface INewDepartments {
  description: string;
}
interface INewDepartmentsStatus {
  status: string;
}
export default class DepartmentsRepository extends Repository {
  async getAll() {
    return await this.datasource.get(`/api/departments`);
  }

  async getById(id: number | string) {
    return await this.datasource.get(`/api/departments/${id}`);
  }

  async create(data: INewDepartments) {
    return await this.datasource.send(`/api/departments`, data);
  }

  async update(id: number | string, data: INewDepartments) {
    return await this.datasource.updatePatch(`/api/departments/${id}`, data);
  }

  async updateStatus(id: number | string, data: INewDepartmentsStatus) {
    return await this.datasource.updatePatch(
      `/api/departments/${id}/update-status`,
      data
    );
  }
}
