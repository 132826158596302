
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class SnackBarNotification extends Vue {
  opened = false;
  icon = "";
  message = "";
  color = "#5867dd";

  show(message: string, icon: string, color: string) {
    this.message = message;
    this.icon = icon;
    this.color = color;
    this.opened = true;
  }
  error(message) {
    this.show(message, "mdi-alert-circle", "#FF1744");
  }
  info(message) {
    this.show(message, "mdi-alert-information", "#5867dd");
  }
  success(message) {
    this.show(message, "mdi-check-circle", "#34bfa3");
  }
  warn(message) {
    this.show(message, "mdi-alert", "#FB8C00");
  }
  hasNewLine(message) {
    return message.includes("<br>");
  }
  newLineToBreakRow(message) {
    return message.replace(/\n/g, "<br>");
  }
}
