
import Vue from "vue";
import Component from "vue-class-component";
import { Location } from "vue-router";

import SquadsRepository from "@/repositories/SquadsRepository";
import ISquads from "@/domain/interfaces/ISquads";

@Component
export default class SquadsList extends Vue {
  pageTitle = "Squads";
  pageIcon = "mdi-account-group-outline";
  pageSubtitle = "Liste e cadastre os squads aqui";
  isNoDataText = "Não há squads cadastrados";
  listRoute = "squads";
  registerRoute = "squadsRegister";
  editRoute = "squadsEdit";
  filterTerm = "";
  filterStatus = "";
  statusItems = [
    { text: "Todos os status", value: "" },
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];
  loadingData = false;
  isLoadingStatus = false;
  readonly SquadsRepository: SquadsRepository = new SquadsRepository();
  headers = [
    { text: "Status", value: "status", sortable: false, width: "5%" },
    {
      text: "Ícone",
      value: "image",
      sortable: false,
      align: "center",
      width: "15%",
    },
    {
      text: "Descrição",
      value: "description",
      sortable: false,
      width: "65%",
      align: "start",
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      width: "5%",
      align: "end",
    },
  ];
  squads: Array<ISquads> = [];

  mounted() {
    this.getAll();
    this.getPageInfo();
  }

  getPageInfo() {
    if (localStorage.menus != "" && localStorage.menus != "null") {
      var getMenus = JSON.parse(localStorage.menus);
      var getRouteName = this.$route.name;
      var getMenuSelectedByRoute = getMenus.filter(function (value) {
        return value.route === getRouteName;
      });
      if (
        getMenuSelectedByRoute[0].description != "" &&
        getMenuSelectedByRoute[0].description
      ) {
        this.pageTitle = getMenuSelectedByRoute[0].description;
      }
      if (
        getMenuSelectedByRoute[0].icon != "" &&
        getMenuSelectedByRoute[0].icon
      ) {
        this.pageIcon = getMenuSelectedByRoute[0].icon;
      }
      if (
        getMenuSelectedByRoute[0].observation != "" &&
        getMenuSelectedByRoute[0].observation
      ) {
        this.pageSubtitle = getMenuSelectedByRoute[0].observation;
      }
    }
  }

  async getAll() {
    try {
      this.loadingData = true;
      const response = await this.SquadsRepository.getAll();
      this.squads = JSON.parse(response.data).squads.data;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  get filteredData() {
    return this.squads.filter(this.filterByTerm).filter(this.filterByStatus);
  }

  filterByTerm(squads: ISquads) {
    const filterTerm = this.filterTerm.toLowerCase();
    return filterTerm != ""
      ? squads.description.toLowerCase().includes(filterTerm)
      : true;
  }

  filterByStatus(squads: ISquads) {
    const status = this.filterStatus;
    return status != ""
      ? (squads.status === "A") == (this.filterStatus === "A")
      : true;
  }

  async changeStatus(squadsId: string, status: boolean) {
    try {
      this.loadingData = true;
      this.isLoadingStatus = true;
      var statusData = {
        status: "",
      };
      if (status === true) {
        statusData.status = "A";
      } else {
        statusData.status = "I";
      }
      const responseStatus = await this.SquadsRepository.updateStatus(
        squadsId,
        statusData
      );
      if (responseStatus.status === 200 || responseStatus.status === 201) {
        this.$notification.success("Status alterado com sucesso!");
      }
    } catch (error: any) {
      console.error(JSON.parse(error.response.data));
    } finally {
      this.loadingData = false;
      this.isLoadingStatus = false;
    }
  }

  editItem(squadsId: string) {
    const params: Location = {
      name: this.editRoute,
      params: {
        id: squadsId,
      },
    };
    this.$router.push(params);
  }
}
