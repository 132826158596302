import Repository from "./base/Repository";

interface INewUser {
  name: string;
  email: string;
  password?: string;
  password_confirmation?: string;
  status: string;
  user_type_id: number | string;
  squad_id: number | null;
  department_id: number | null;
}

interface INewUserStatus {
  status: string;
}

interface INewUserAvatar {
  avatar: File;
}
interface IChangePassword {
  current_password?: string;
  password?: string;
  password_confirmation?: string;
}

export default class UsersRepository extends Repository {
  async getCurrentUser() {
    return await this.datasource.get(`/api/users/me`);
  }

  async getAll() {
    return await this.datasource.get(`/api/users`);
  }

  async getById(id: number | string) {
    return await this.datasource.get(`/api/users/${id}`);
  }

  async create(data: INewUser) {
    return await this.datasource.send(`/api/users`, data);
  }

  async update(id: number | string, data: INewUser) {
    return await this.datasource.updatePatch(`/api/users/${id}`, data);
  }

  async updateStatus(id: number | string, data: INewUserStatus) {
    return await this.datasource.updatePatch(
      `/api/users/${id}/update-status`,
      data
    );
  }

  async updateAvatar(data: INewUserAvatar) {
    return await this.datasource.upload(`/api/users/avatar`, data);
  }

  async changePassword(data: IChangePassword) {
    return await this.datasource.updatePatch(`/api/change-password`, data);
  }
}
