import Repository from "./base/Repository";

interface INewMenu {
  description: string;
  route: string;
  icon: string;
}
interface INewMenuStatus {
  status: string;
}
export default class MenusRepository extends Repository {
  async getAll() {
    return await this.datasource.get(`/api/menus`);
  }

  async getById(id: number | string) {
    return await this.datasource.get(`/api/menus/${id}`);
  }

  async create(data: INewMenu) {
    return await this.datasource.send(`/api/menus`, data);
  }

  async update(id: number | string, data: INewMenu) {
    return await this.datasource.updatePatch(`/api/menus/${id}`, data);
  }

  async updateStatus(id: number | string, data: INewMenuStatus) {
    return await this.datasource.updatePatch(
      `/api/menus/${id}/update-status`,
      data
    );
  }
}
