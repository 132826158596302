
import Vue from "vue";
import Component from "vue-class-component";
import { Location } from "vue-router";
import draggable from "vuedraggable";

import MenusRepository from "@/repositories/MenusRepository";
import IMenus from "@/domain/interfaces/IMenus";

@Component({
  components: {
    draggable,
  },
})
export default class MenusList extends Vue {
  pageTitle = "Menus";
  pageIcon = "mdi-laptop";
  pageSubtitle = "Liste e cadastre os menus aqui";
  isNoDataText = "Não há menus cadastrados";
  listRoute = "menus";
  registerRoute = "menusRegister";
  editRoute = "menusEdit";
  filterTerm = "";
  filterStatus = "";
  statusItems = [
    { text: "Todos os status", value: "" },
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];
  loadingData = false;
  isLoadingStatus = false;
  readonly MenusRepository: MenusRepository = new MenusRepository();
  headers = [
    {
      text: "",
      value: "draggable",
      sortable: false,
      width: "5%",
      align: "start",
    },
    {
      text: "Ordem",
      value: "order",
      sortable: false,
      width: "5%",
      align: "start",
    },
    {
      text: "Status",
      value: "status",
      sortable: false,
      width: "5%",
      align: "start",
    },
    {
      text: "Ícone",
      value: "icon",
      sortable: false,
      width: "10%",
      align: "center",
    },
    {
      text: "Descrição",
      value: "description",
      sortable: false,
      width: "45%",
      align: "start",
    },
    {
      text: "Rota",
      value: "route",
      sortable: false,
      width: "45%",
      align: "start",
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      width: "5%",
      align: "end",
    },
  ];
  menus: Array<IMenus> = [];
  disableDragging = false;

  mounted() {
    this.getAll();
    this.getPageInfo();
  }

  getPageInfo() {
    if (localStorage.menus != "" && localStorage.menus != "null") {
      var getMenus = JSON.parse(localStorage.menus);
      var getRouteName = this.$route.name;
      var getMenuSelectedByRoute = getMenus.filter(function (value) {
        return value.route === getRouteName;
      });
      if (
        getMenuSelectedByRoute[0].description != "" &&
        getMenuSelectedByRoute[0].description
      ) {
        this.pageTitle = getMenuSelectedByRoute[0].description;
      }
      if (
        getMenuSelectedByRoute[0].icon != "" &&
        getMenuSelectedByRoute[0].icon
      ) {
        this.pageIcon = getMenuSelectedByRoute[0].icon;
      }
      if (
        getMenuSelectedByRoute[0].observation != "" &&
        getMenuSelectedByRoute[0].observation
      ) {
        this.pageSubtitle = getMenuSelectedByRoute[0].observation;
      }
    }
  }

  async getAll() {
    try {
      this.loadingData = true;
      const response = await this.MenusRepository.getAll();
      this.menus = JSON.parse(response.data).menus.data;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  orderUpdate(item) {
    try {
      this.disableDragging = true;
      var newList = [];
      item.map(function (value: IMenus) {
        var newOrder = item.indexOf(value) + 1;
        if (value.order != newOrder) {
          value.order = newOrder;
          var newObject = {
            id: value.id,
            order: value.order,
          };
          newList.push(newObject);
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.disableDragging = false;
    }
  }

  get filteredData() {
    return this.menus.filter(this.filterByTerm).filter(this.filterByStatus);
  }

  filterByTerm(menus: IMenus) {
    const filterTerm = this.filterTerm.toLowerCase();
    return filterTerm != ""
      ? menus.description.toLowerCase().includes(filterTerm) ||
          menus.route.toLowerCase().includes(filterTerm)
      : true;
  }

  filterByStatus(menus: IMenus) {
    const status = this.filterStatus;
    return status != ""
      ? (menus.status === "A") == (this.filterStatus === "A")
      : true;
  }

  async changeStatus(menusId: string, status: boolean) {
    try {
      this.loadingData = true;
      this.isLoadingStatus = true;
      var statusData = {
        status: "",
      };
      if (status === true) {
        statusData.status = "A";
      } else {
        statusData.status = "I";
      }
      const responseStatus = await this.MenusRepository.updateStatus(
        menusId,
        statusData
      );
      if (responseStatus.status === 200 || responseStatus.status === 201) {
        this.$notification.success("Status alterado com sucesso!");
      }
    } catch (error: any) {
      console.error(JSON.parse(error.response.data));
    } finally {
      this.loadingData = false;
      this.isLoadingStatus = false;
    }
  }

  editItem(menusId: string) {
    const params: Location = {
      name: this.editRoute,
      params: {
        id: menusId,
      },
    };
    this.$router.push(params);
  }
}
