import Repository from "./base/Repository";

export default class AuthRepository extends Repository {
  async login(record: object) {
    return await this.datasource.send(`/api/login`, record);
  }

  async getCurrentUser() {
    return await this.datasource.get(`/api/users/me`);
  }
}
