
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import CustomTabsApp from "./components/CustomTabsApp.vue";
import AppBar from "./components/AppBar.vue";
import AuthRepository from "@/repositories/AuthRepository";
import ICurrentUser from "@/domain/interfaces/ICurrentUser";

interface ITabs {
  id: number;
  description: string;
  icon: string;
  route: string;
  status: string;
  created_at: string;
  updated_at: string;
  pivot: {
    menu_id: number;
    user_type_id: number;
  };
}

@Component({
  components: {
    CustomTabsApp,
    AppBar,
  },
})
export default class AppView extends Vue {
  readonly AuthRepository: AuthRepository = new AuthRepository();
  items: Array<ITabs> = [];
  drawer = false;
  group = null;
  currentUser: ICurrentUser = {} as ICurrentUser;
  loadingData = false;
  showAppBar = false;

  async mounted() {
    if (this.$router.currentRoute.name === "home") {
      if (localStorage.token && localStorage.token != "") {
        this.$router.push({ name: "dashboard" });
      } else {
        this.$router.push({ name: "login" });
      }
    }
    if (localStorage.token && localStorage.token != "") {
      this.getCurrentUser();
    }
  }

  async getCurrentUser() {
    try {
      this.loadingData = true;
      const responseCurrentUser = await this.AuthRepository.getCurrentUser();
      var currentUserFiltered = JSON.parse(responseCurrentUser.data).user;
      this.currentUser = {
        id: currentUserFiltered.id,
        name: currentUserFiltered.name,
        type_description: currentUserFiltered.type.description,
        avatar_path: currentUserFiltered.avatar_path,
        squad: currentUserFiltered.squad,
        department: currentUserFiltered.department,
      };
      this.items = this.filterByActiveStatus(currentUserFiltered.type.menus);
      localStorage.userId = currentUserFiltered.id;
      localStorage.userName = currentUserFiltered.name;
      localStorage.email = currentUserFiltered.email;
      localStorage.status = currentUserFiltered.status;
      localStorage.userTypeName = currentUserFiltered.type.description;
      localStorage.userTypeDashboard = currentUserFiltered.type.dashboard;
      localStorage.userTypeId = currentUserFiltered.type.id;
      localStorage.menus = JSON.stringify(currentUserFiltered.type.menus);
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }
  filterByActiveStatus(tabs: Array<ITabs>) {
    return tabs.filter(function (value) {
      return value.status === "A";
    });
  }

  logOut(routeName) {
    localStorage.token = "";
    this.getRoute(routeName);
  }

  getRoute(data: string) {
    this.goToRoute(data);
  }

  getDrawer(data: boolean) {
    this.drawer = data;
  }

  verifyRoute(routeName: string) {
    if (this.$router.currentRoute.name === routeName) {
      return true;
    }
    return false;
  }

  goToRoute(routeName: string) {
    if (!this.verifyRoute(routeName)) {
      this.$router.push({ name: routeName });
    }
  }

  @Watch("group")
  Group() {
    this.drawer = false;
  }

  get hideAll(): boolean {
    return this.$route.path === "/login" || this.$route.path === "/profile";
  }
}
