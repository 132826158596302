
import Vue from "vue";
import Component from "vue-class-component";
import UsersRepository from "@/repositories/UsersRepository";
import IUsers from "@/domain/interfaces/IUsers";
import UserTypesRepository from "@/repositories/UserTypesRepository";
import IUserTypes from "@/domain/interfaces/IUserTypes";
import DepartmentsRepository from "@/repositories/DepartmentsRepository";
import IDepartments from "@/domain/interfaces/IDepartments";
import SquadsRepository from "@/repositories/SquadsRepository";
import ISquads from "@/domain/interfaces/ISquads";

@Component({})
export default class UserRegister extends Vue {
  readonly UsersRepository: UsersRepository = new UsersRepository();
  readonly UserTypesRepository: UserTypesRepository = new UserTypesRepository();
  readonly DepartmentsRepository: DepartmentsRepository =
    new DepartmentsRepository();
  readonly SquadsRepository: SquadsRepository = new SquadsRepository();
  pageTitle = "Cadastro de Usuário";
  listRoute = "users";
  registerRoute = "usersRegister";
  editRoute = "usersEdit";
  statusItems = [
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];
  loadingData = false;
  loadingSave = false;
  rules = {
    required: [(value: string) => !!value || "Campo obrigatório."],
    email: [(v: string) => /.+@.+\..+/.test(v) || "Email inválido."],
    password: [
      (value: string) =>
        (!!value && value.length >= 6) ||
        "A senha deve conter pelo menos 6 caracteres.",
    ],
    confirmPassword: [
      (value: string) =>
        this.validatePassword(value) || "As senhas não se conincidem.",
    ],
    changePassword: [
      (value: string) =>
        this.validateChangePassword(value) ||
        "A senha deve conter pelo menos 6 caracteres.",
    ],
    changeConfirmPassword: [
      (value: string) =>
        this.validateChangeConfirmationPassword(value) ||
        "As senhas não se conincidem.",
    ],
    username: [
      (value: string) =>
        (!!value && value.length >= 3) ||
        "O nome deve conter pelo menos 3 caracteres.",
    ],
  };
  passwordValue = true;
  confirmPasswordValue = true;
  newUser: IUsers = {} as IUsers;
  users: Array<IUsers> = [];
  userTypesItems: Array<IUserTypes> = [];
  departmentItems: Array<IDepartments> = [];
  squadsItems: Array<ISquads> = [];

  mounted() {
    this.loadUserTypes();
    this.loadDepartments();
    this.loadSquads();
    if (this.$route.params.id) {
      this.pageTitle = "Editar Usuário";
      this.loadById(this.$route.params.id);
    } else {
      this.pageTitle = "Cadastrar Usuário";
      this.newUser.status = "A";
      this.newUser.department_id = null;
      this.newUser.squad_id = null;
    }
  }
  async saveUser() {
    if (this.validateForm()) {
      var data = {
        name: this.newUser.name,
        email: this.newUser.email,
        password: this.newUser.password,
        password_confirmation: this.newUser.password_confirmation,
        status: this.newUser.status,
        user_type_id: this.newUser.user_type_id,
        department_id: this.newUser.department_id,
        squad_id: this.newUser.squad_id,
      };
      try {
        this.loadingSave = true;
        if (this.$route.params.id) {
          const response = await this.UsersRepository.update(
            this.$route.params.id,
            data
          );
          if (response.status === 200 || response.status === 201) {
            this.$notification.success("Usuário editado com sucesso!");
            this.$router.push({ name: "users" });
          }
        } else {
          const response = await this.UsersRepository.create(data);
          if (response.status === 200 || response.status === 201) {
            this.$notification.success("Usuário criado com sucesso!");
            this.$router.push({ name: "users" });
          }
        }
        // eslint-disable-next-line
      } catch (error: any) {
        var message = JSON.parse(error.response.data).errors;
        if (message.email) {
          this.$notification.error(message.email[0]);
        } else {
          this.$notification.error(message);
        }
      } finally {
        this.loadingSave = false;
      }
    }
  }

  async loadUserTypes() {
    try {
      this.loadingData = true;
      const response = await this.UserTypesRepository.getAll();
      this.userTypesItems = this.filterByActiveStatus(
        JSON.parse(response.data).userTypes.data
      );
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  async loadDepartments() {
    try {
      this.loadingData = true;
      const response = await this.DepartmentsRepository.getAll();
      const responseFiltered = this.filterByActiveStatus(
        JSON.parse(response.data).departments.data
      );
      var newDepartmentItem = [
        {
          id: null,
          description: "Nenhum Departamento",
          status: "A",
        },
      ];

      var departmentsData = newDepartmentItem.concat(responseFiltered);
      this.departmentItems = departmentsData;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  async loadSquads() {
    try {
      this.loadingData = true;
      const response = await this.SquadsRepository.getAll();
      const responseFiltered = this.filterByActiveStatus(
        JSON.parse(response.data).squads.data
      );
      var newSquadItem = [
        {
          id: null,
          description: "Nenhum Squad",
          status: "A",
        },
      ];

      var squadsData = newSquadItem.concat(responseFiltered);
      this.squadsItems = squadsData;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  async loadById(id: string | number) {
    try {
      this.loadingData = true;
      const response = await this.UsersRepository.getById(id);
      this.newUser = JSON.parse(response.data).user;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  validatePassword(value: string) {
    if (this.newUser.password === value) {
      return true;
    }
    return false;
  }

  validateChangePassword(value: string) {
    if (value) {
      if (value.length > 0 && value.length >= 6) {
        return true;
      }
      return false;
    }
    return true;
  }

  validateChangeConfirmationPassword(value: string) {
    if (this.newUser.password) {
      if (this.newUser.password.length > 0) {
        if (value && this.newUser.password === value) {
          return true;
        }
        return false;
      }
      return true;
    }
    return true;
  }

  filterByActiveStatus(item: Array<any>) {
    return item.filter(function (value) {
      return value.status === "A";
    });
  }

  validateForm(): boolean {
    return (
      this.$refs.registerUserForm as Vue & { validate: () => boolean }
    ).validate();
  }
}
