
import Vue from "vue";
import { Component } from "vue-property-decorator";
import AuthRepository from "@/repositories/AuthRepository";
import UsersRepository from "@/repositories/UsersRepository";
import ChangeAvatarButton from "@/components/ChangeAvatarButton.vue";
import ICurrentUser from "@/domain/interfaces/ICurrentUser";

@Component({
  components: {
    ChangeAvatarButton,
  },
})
export default class ProfileView extends Vue {
  pageTitle = "Perfil";
  rules = {
    username: [
      (value: string) =>
        (!!value && value.length >= 3) ||
        "O nome deve conter pelo menos 3 caracteres.",
    ],
    changePassword: [
      (value: string) =>
        this.validateChangePassword(value) ||
        "A senha deve conter pelo menos 6 caracteres.",
    ],
    changeCurrentPassword: [
      (value: string) =>
        this.validateChangeOldPassword(value) ||
        "É obrigatório informar a senha antiga.",
    ],
    changeConfirmPassword: [
      (value: string) =>
        this.isTryingChangePassword(value) || "As senhas não se conincidem.",
    ],
  };
  readonly UsersRepository: UsersRepository = new UsersRepository();
  readonly AuthRepository: AuthRepository = new AuthRepository();
  loadingData = false;
  loadingSave = false;
  currentUser: ICurrentUser = {} as ICurrentUser;
  showChangeAvatarDialog = false;
  currentPasswordValue = true;
  passwordValue = true;
  confirmPasswordValue = true;
  ChangeAvatarDialogTitle = "Alteração do Avatar";

  mounted() {
    this.getCurrentUser();
  }

  async getCurrentUser() {
    try {
      this.loadingData = true;
      const response = await this.AuthRepository.getCurrentUser();
      const currentUserFiltered = JSON.parse(response.data).user;
      this.currentUser = {
        id: currentUserFiltered.id,
        name: currentUserFiltered.name,
        email: currentUserFiltered.email,
        status: currentUserFiltered.status,
        user_type_id: currentUserFiltered.type.id,
        type_description: currentUserFiltered.type.description,
        department_id: currentUserFiltered.department_id,
        department: currentUserFiltered.department,
        squad: currentUserFiltered.squad,
        squad_id: currentUserFiltered.squad_id,
        avatar_path: currentUserFiltered.avatar_path,
      };
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  async saveData() {
    if (this.validateForm()) {
      try {
        this.loadingData = true;
        var data = {
          id: this.currentUser.id,
          name: this.currentUser.name,
          email: this.currentUser.email,
          user_type_id: this.currentUser.user_type_id,
          department_id: this.currentUser.department_id,
          squad_id: this.currentUser.squad_id,
          status: this.currentUser.status,
        };
        var dataNewPassword = {
          current_password: this.currentUser.current_password,
          password: this.currentUser.password,
          password_confirmation: this.currentUser.password_confirmation,
        };
        if (
          dataNewPassword.current_password &&
          dataNewPassword.password &&
          dataNewPassword.password_confirmation
        ) {
          const response = await this.UsersRepository.update(data.id, data);
          const responsePassword = await this.UsersRepository.changePassword(
            dataNewPassword
          );
          if (
            (response.status === 200 || response.status === 201) &&
            (responsePassword.status === 200 || responsePassword.status === 201)
          ) {
            this.$notification.success("Perfil editado com sucesso!");
            this.$router.go(-1);
          }
        } else {
          const response = await this.UsersRepository.update(data.id, data);
          if (response.status === 200 || response.status === 201) {
            this.$notification.success("Perfil editado com sucesso!");
            this.$router.go(-1);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingData = false;
      }
    }
  }

  getCurrentAvatar() {
    if (
      this.currentUser.avatar_path != "" &&
      this.currentUser.avatar_path != null
    ) {
      return this.currentUser.avatar_path;
    } else if (this.currentUser.squad) {
      if (
        this.currentUser.squad.image_path != "" &&
        this.currentUser.squad.image_path != null
      ) {
        return this.currentUser.squad.image_path;
      }
    } else {
      return "";
    }
  }

  async getNewAvatar(file: File) {
    try {
      var data = {
        avatar: file,
      };
      this.loadingData = true;
      const response = await this.UsersRepository.updateAvatar(data);
      const responseStatus = JSON.parse(response.data).status;
      if (responseStatus === 200 || responseStatus === 201) {
        this.$notification.success("Foto de Perfil editada com sucesso!");
        this.currentUser.avatar_path = JSON.parse(response.data).avatar_path;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  validateChangePassword(value: string) {
    if (value) {
      if (value.length > 0 && value.length >= 6) {
        return true;
      }
      return false;
    }
    return true;
  }

  isTryingChangePassword(value: string) {
    if (this.currentUser.password) {
      if (this.currentUser.password.length > 0) {
        if (value && this.currentUser.password === value) {
          return true;
        }
        return false;
      }
      return true;
    }
    return true;
  }

  validateChangeOldPassword(value: string) {
    if (this.currentUser.password) {
      if (this.currentUser.password.length > 0) {
        if (value) {
          return true;
        }
        return false;
      }
      return true;
    }
    return true;
  }

  validateForm(): boolean {
    return (
      this.$refs.profileForm as Vue & { validate: () => boolean }
    ).validate();
  }
}
