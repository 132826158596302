import Repository from "./base/Repository";

interface INewJob {
  id?: number;
  title?: string;
  description?: string;
  status?: string;
  user_id?: number;
  department_id?: number;
  squad_id?: number;
  quantity?: number;
  workplace?: string;
}
interface INewJobStatus {
  status: string;
}
export default class JobsRepository extends Repository {
  async getAll() {
    return await this.datasource.get(`/api/jobs`);
  }

  async getById(id: number | string) {
    return await this.datasource.get(`/api/jobs/${id}`);
  }

  async create(data: INewJob) {
    return await this.datasource.send(`/api/jobs`, data);
  }

  async update(id: number | string, data: INewJob) {
    return await this.datasource.updatePatch(`/api/jobs/${id}`, data);
  }

  async updateStatus(id: number | string, data: INewJobStatus) {
    return await this.datasource.updatePatch(
      `/api/jobs/${id}/update-status`,
      data
    );
  }
}
