
import Vue from "vue";
import Component from "vue-class-component";
import { VueEditor } from "vue2-editor";
import JobsRepository from "@/repositories/JobsRepository";
import IJobs from "@/domain/interfaces/IJobs";
import DepartmentsRepository from "@/repositories/DepartmentsRepository";
import IDepartments from "@/domain/interfaces/IDepartments";
import SquadsRepository from "@/repositories/SquadsRepository";
import ISquads from "@/domain/interfaces/ISquads";

@Component({
  components: {
    VueEditor,
  },
})
export default class JobsRegister extends Vue {
  readonly JobsRepository: JobsRepository = new JobsRepository();
  readonly DepartmentsRepository: DepartmentsRepository =
    new DepartmentsRepository();
  readonly SquadsRepository: SquadsRepository = new SquadsRepository();
  pageIcon = "mdi-account";
  pageTitle = "";
  listRoute = "jobs";
  registerRoute = "jobsRegister";
  editRoute = "jobsEdit";
  loadingData = false;
  loadingSave = false;
  statusItems = [
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];
  workplaceItems = [
    { text: "Remoto", value: "R" },
    { text: "Presencial", value: "P" },
  ];
  rules = {
    required: [(value: string) => !!value || "Campo obrigatório."],
  };
  newJob: IJobs = {} as IJobs;
  jobs: Array<IJobs> = [];
  departmentItems: Array<IDepartments> = [];
  squadsItems: Array<ISquads> = [];

  mounted() {
    this.loadDepartments();
    this.loadSquads();
    if (this.$route.params.id) {
      this.pageTitle = "Editar Vaga";
      this.loadById(this.$route.params.id);
    } else {
      this.pageTitle = "Cadastrar Vaga";
      this.newJob.status = "A";
      this.newJob.quantity = 1;
    }
  }

  getPageRegisterInfo() {
    if (this.$pageTitle != "" && this.$pageTitle != "null") {
      if (this.$route.params.id) {
        this.pageTitle = `Editar ${this.$pageTitle}`;
      } else {
        this.pageTitle = `Cadastrar ${this.$pageTitle}`;
      }
    }
    if (this.$pageIcon != "" && this.$pageIcon != "null") {
      this.pageIcon = this.$pageIcon;
    }
  }

  async saveData() {
    if (this.validateForm()) {
      if (this.newJob.description) {
        var data = {
          title: this.newJob.title,
          description: this.newJob.description,
          status: this.newJob.status,
          user_id: localStorage.userId,
          department_id: this.newJob.department_id,
          squad_id: this.newJob.squad_id,
          quantity: this.newJob.quantity,
          workplace: this.newJob.workplace,
        };
        var statusData = {
          status: this.newJob.status,
        };
        try {
          this.loadingSave = true;
          if (this.$route.params.id) {
            const response = await this.JobsRepository.update(
              this.$route.params.id,
              data
            );
            const responseStatus = await this.JobsRepository.updateStatus(
              this.$route.params.id,
              statusData
            );
            if (
              (response.status === 200 || response.status === 201) &&
              (responseStatus.status === 200 || responseStatus.status === 201)
            ) {
              this.$notification.success("Vaga editada com sucesso!");
              this.$router.push({ name: "jobs" });
            }
          } else {
            const response = await this.JobsRepository.create(data);
            if (response.status === 200 || response.status === 201) {
              this.$notification.success("Vaga criada com sucesso!");
              this.$router.push({ name: "jobs" });
            }
          }
          // eslint-disable-next-line
        } catch (error: any) {
          var message = JSON.parse(error.response.data).errors;
          var textError = "";
          if (message) {
            for (var index in message) {
              var getFirstMessage = message[index];
              textError = `${getFirstMessage[0]}`;
              this.$notification.error(textError);
            }
          }
        } finally {
          this.loadingSave = false;
        }
      } else {
        this.$notification.error(
          "O campo de descrição da vaga não pode ficar vazio."
        );
      }
    }
  }

  async loadDepartments() {
    try {
      this.loadingData = true;
      const response = await this.DepartmentsRepository.getAll();
      this.departmentItems = this.filterByActiveStatus(
        JSON.parse(response.data).departments.data
      );
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  async loadSquads() {
    try {
      this.loadingData = true;
      const response = await this.SquadsRepository.getAll();
      this.squadsItems = this.filterByActiveStatus(
        JSON.parse(response.data).squads.data
      );
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  async loadById(id: string | number) {
    try {
      this.loadingData = true;
      const response = await this.JobsRepository.getById(id);
      this.newJob = JSON.parse(response.data).job;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  validateForm() {
    return (
      this.$refs.registerJobForm as Vue & { validate: () => boolean }
    ).validate();
  }

  filterByActiveStatus(item: Array<any>) {
    return item.filter(function (value) {
      return value.status === "A";
    });
  }
}
