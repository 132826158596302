
import Vue from "vue";
import Component from "vue-class-component";

import DepartmentsRepository from "@/repositories/DepartmentsRepository";
import IDepartments from "@/domain/interfaces/IDepartments";

@Component
export default class DepartmentsRegister extends Vue {
  pageTitle = "";
  pageIcon = "mdi-laptop";
  listRoute = "departments";
  registerRoute = "departmentsRegister";
  editRoute = "departmentsEdit";
  readonly DepartmentsRepository: DepartmentsRepository =
    new DepartmentsRepository();
  newDepartments: IDepartments = {} as IDepartments;
  departments: Array<IDepartments> = [];
  statusItems = [
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];
  rules = {
    required: [(value: string) => !!value || "Campo obrigatório."],
  };
  loadingData = false;
  loadingSave = false;

  mounted() {
    if (this.$route.params.id) {
      this.pageTitle = "Editar Tela";
      this.loadById(this.$route.params.id);
    } else {
      this.pageTitle = "Cadastrar Tela";
      this.newDepartments.status = "A";
    }
  }
  async loadById(id: string | number) {
    try {
      this.loadingData = true;
      const response = await this.DepartmentsRepository.getById(id);
      this.newDepartments = JSON.parse(response.data).department;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  async saveData() {
    if (this.validateForm()) {
      var data = {
        description: this.newDepartments.description,
      };
      var statusData = {
        status: this.newDepartments.status,
      };

      try {
        this.loadingSave = true;
        if (this.$route.params.id) {
          const response = await this.DepartmentsRepository.update(
            this.$route.params.id,
            data
          );
          const responseStatus = await this.DepartmentsRepository.updateStatus(
            this.$route.params.id,
            statusData
          );
          if (
            (response.status === 200 || response.status === 201) &&
            (responseStatus.status === 200 || responseStatus.status === 201)
          ) {
            this.$notification.success("Tela editada com sucesso!");
            this.$router.push({ name: "departments" });
          }
        } else {
          const response = await this.DepartmentsRepository.create(data);
          if (response.status === 200 || response.status === 201) {
            this.$notification.success("Tela criada com sucesso!");
            this.$router.push({ name: "departments" });
          }
        }
        // eslint-disable-next-line
      } catch (error: any) {
        var message = JSON.parse(error.response.data).errors;
        if (message.dashboard) {
          this.$notification.error(message.dashboard[0]);
        } else if (message.description) {
          this.$notification.error(message.description[0]);
        } else {
          this.$notification.error(message);
        }
      } finally {
        this.loadingSave = false;
      }
    }
  }

  validateForm() {
    return (
      this.$refs.registerDepartmentsForm as Vue & { validate: () => boolean }
    ).validate();
  }
}
