
import Vue from "vue";
import Component from "vue-class-component";
import AuthRepository from "@/repositories/AuthRepository";

@Component({})
export default class Home extends Vue {
  readonly AuthRepository: AuthRepository = new AuthRepository();

  mounted() {
    if (this.$router.currentRoute.name === "home") {
      if (localStorage.token && localStorage.token != "") {
        this.$router.push({ name: "dashboard" });
      } else {
        this.$router.push({ name: "login" });
      }
    }
  }
}
