
import Vue from "vue";
import Component from "vue-class-component";

import MenusRepository from "@/repositories/MenusRepository";
import IMenus from "@/domain/interfaces/IMenus";

@Component
export default class MenusRegister extends Vue {
  readonly MenusRepository: MenusRepository = new MenusRepository();
  pageTitle = "";
  listRoute = "menus";
  registerRoute = "menusRegister";
  editRoute = "menusEdit";
  newMenu: IMenus = {} as IMenus;
  userTypes: Array<IMenus> = [];
  statusItems = [
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];
  rules = {
    required: [(value: string) => !!value || "Campo obrigatório."],
    notAllowZero: [
      (value: number) => value != 0 || "A ordem deve ser maior que zero!",
    ],
  };
  loadingData = false;
  loadingSave = false;

  mounted() {
    if (this.$route.params.id) {
      this.pageTitle = "Editar Permissão de Acesso";
      this.loadById(this.$route.params.id);
    } else {
      this.newMenu.order = 99;
      this.pageTitle = "Cadastrar Permissão de Acesso";
      this.newMenu.status = "A";
    }
  }
  async loadById(id: string | number) {
    try {
      this.loadingData = true;
      const response = await this.MenusRepository.getById(id);
      this.newMenu = JSON.parse(response.data).menu;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  async saveData() {
    if (this.validateForm()) {
      var data = {
        description: this.newMenu.description,
        route: this.newMenu.route,
        icon: this.newMenu.icon,
        order: this.newMenu.order,
        observation: this.newMenu.observation,
      };
      var statusData = {
        status: this.newMenu.status,
      };

      try {
        this.loadingSave = true;
        if (this.$route.params.id) {
          const response = await this.MenusRepository.update(
            this.$route.params.id,
            data
          );
          const responseStatus = await this.MenusRepository.updateStatus(
            this.$route.params.id,
            statusData
          );
          if (
            (response.status === 200 || response.status === 201) &&
            (responseStatus.status === 200 || responseStatus.status === 201)
          ) {
            this.$notification.success(
              "Permissão de Acesso editada com sucesso!"
            );
            this.$router.push({ name: "menus" });
          }
        } else {
          const response = await this.MenusRepository.create(data);
          if (response.status === 200 || response.status === 201) {
            this.$notification.success(
              "Permissão de Acesso criada com sucesso!"
            );
            this.$router.push({ name: "menus" });
          }
        }
        // eslint-disable-next-line
      } catch (error: any) {
        var message = JSON.parse(error.response.data).errors;
        var textError = "";
        if (message) {
          for (var index in message) {
            var getFirstMessage = message[index];
            textError = `${getFirstMessage[0]}`;
            this.$notification.error(textError);
          }
        }
      } finally {
        this.loadingSave = false;
      }
    }
  }

  validateForm() {
    return (
      this.$refs.registerMenusForm as Vue & { validate: () => boolean }
    ).validate();
  }
}
