
import Vue from "vue";
import { Prop, Component, Emit, Watch } from "vue-property-decorator";
import CropperImage from "@/components/CropperImage.vue";

@Component({
  components: {
    CropperImage,
  },
})
export default class ChangeAvatarDialog extends Vue {
  @Prop(String) title!: string;
  @Prop(Boolean) showDialog!: boolean;
  @Prop(Function) toggle!: () => void;
  @Prop(String) ImageToBeCropped!: string;
  loadingData = false;
  loadingSave = false;
  avatarFile: any;

  @Watch("showDialog")
  async showAllAttribute() {
    if (this.showDialog) {
      try {
        this.loadingData = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingData = false;
      }
    }
  }

  getCroppedImage(data) {
    this.avatarFile = data;
  }

  saveData() {
    try {
      this.loadingData = true;
      this.getData(this.avatarFile);
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  @Emit()
  getData(file: File) {
    this.toggle();
    return file;
  }
}
