
import Vue from "vue";
import Component from "vue-class-component";
import { Location } from "vue-router";

import UsersRepository from "@/repositories/UsersRepository";
import IUsers from "@/domain/interfaces/IUsers";

@Component({})
export default class UserView extends Vue {
  pageTitle = "Usuários";
  pageIcon = "mdi-account-outline";
  pageSubtitle = "Liste e cadastre os usuários aqui";
  isNoDataText = "Não há usuários cadastrados";
  listRoute = "users";
  registerRoute = "usersRegister";
  editRoute = "usersEdit";
  filterTerm = "";
  filterStatus = "";
  statusItems = [
    { text: "Todos os status", value: "" },
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];
  loadingData = false;
  isLoadingStatus = false;
  readonly UsersRepository: UsersRepository = new UsersRepository();
  headers = [
    {
      text: "Status",
      value: "status",
      sortable: false,
      width: "5%",
      align: "start",
    },
    {
      text: "Nome de Usuário",
      value: "name",
      sortable: false,
      width: "45%",
      align: "start",
    },
    {
      text: "Email",
      value: "email",
      sortable: false,
      width: "45%",
      align: "start",
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      width: "5%",
      align: "end",
    },
  ];
  users: Array<IUsers> = [];

  mounted() {
    this.getAll();
    this.getPageInfo();
  }

  getPageInfo() {
    if (localStorage.menus != "" && localStorage.menus != "null") {
      var getMenus = JSON.parse(localStorage.menus);
      var getRouteName = this.$route.name;
      var getMenuSelectedByRoute = getMenus.filter(function (value) {
        return value.route === getRouteName;
      });
      if (
        getMenuSelectedByRoute[0].description != "" &&
        getMenuSelectedByRoute[0].description
      ) {
        this.pageTitle = getMenuSelectedByRoute[0].description;
      }
      if (
        getMenuSelectedByRoute[0].icon != "" &&
        getMenuSelectedByRoute[0].icon
      ) {
        this.pageIcon = getMenuSelectedByRoute[0].icon;
      }
      if (
        getMenuSelectedByRoute[0].observation != "" &&
        getMenuSelectedByRoute[0].observation
      ) {
        this.pageSubtitle = getMenuSelectedByRoute[0].observation;
      }
    }
  }

  async getAll() {
    try {
      this.loadingData = true;
      const response = await this.UsersRepository.getAll();
      this.users = JSON.parse(response.data).users.data;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  get filteredData() {
    return this.users.filter(this.filterByTerm).filter(this.filterByStatus);
  }

  filterByTerm(users: IUsers) {
    const filterTerm = this.filterTerm.toLowerCase();
    return filterTerm != ""
      ? users.name.toLowerCase().includes(filterTerm) ||
          users.email.toLowerCase().includes(filterTerm)
      : true;
  }

  filterByStatus(users: IUsers) {
    const status = this.filterStatus;
    return status != ""
      ? (users.status === "A") == (this.filterStatus === "A")
      : true;
  }

  async changeStatus(departmentsId: string, status: boolean) {
    try {
      this.loadingData = true;
      this.isLoadingStatus = true;
      var statusData = {
        status: "",
      };
      if (status === true) {
        statusData.status = "A";
      } else {
        statusData.status = "I";
      }
      const responseStatus = await this.UsersRepository.updateStatus(
        departmentsId,
        statusData
      );
      if (responseStatus.status === 200 || responseStatus.status === 201) {
        this.$notification.success("Status alterado com sucesso!");
      }
    } catch (error: any) {
      console.error(JSON.parse(error.response.data));
    } finally {
      this.loadingData = false;
      this.isLoadingStatus = false;
    }
  }

  editItem(userId: string) {
    const params: Location = {
      name: this.editRoute,
      params: {
        id: userId,
      },
    };
    this.$router.push(params);
  }
}
