
import Vue from "vue";
import Component from "vue-class-component";
import SquadsRepository from "@/repositories/SquadsRepository";
import UsersRepository from "@/repositories/UsersRepository";
import ISquads from "@/domain/interfaces/ISquads";
import IUsers from "@/domain/interfaces/IUsers";
import AuthRepository from "@/repositories/AuthRepository";
import ChangeAvatarButton from "@/components/ChangeAvatarButton.vue";

@Component({
  components: {
    ChangeAvatarButton,
  },
})
export default class SquadsRegister extends Vue {
  pageTitle = "";
  listRoute = "squads";
  registerRoute = "squadsRegister";
  editRoute = "squadsEdit";
  statusItems = [
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];
  rules = {
    required: [(value: string) => !!value || "Campo obrigatório."],
  };
  loadingData = false;
  loadingSave = false;
  readonly SquadsRepository: SquadsRepository = new SquadsRepository();
  readonly AuthRepository: AuthRepository = new AuthRepository();
  readonly UsersRepository: UsersRepository = new UsersRepository();
  newSquad: ISquads = {} as ISquads;
  squads: Array<ISquads> = [];
  usersItems: Array<IUsers> = [];
  isCurrentUser = false;
  ChangeAvatarDialogTitle = "Alteração do Avatar do Squad";
  squadAvatar = "";

  async mounted() {
    if (this.$route.params.id) {
      if (localStorage.userTypeId == this.$route.params.id) {
        this.isCurrentUser = true;
        this.newSquad.status = "A";
      }
      this.pageTitle = "Editar Squad";
      this.loadById(this.$route.params.id);
    } else {
      this.pageTitle = "Cadastrar Squad";
      this.newSquad.status = "A";
    }
    await this.loadUsers();
  }

  async loadById(id: string | number) {
    try {
      this.loadingData = true;
      const response = await this.SquadsRepository.getById(id);
      this.newSquad = JSON.parse(response.data).squad;
      if (this.newSquad.image_path && this.newSquad.image_path != "") {
        this.squadAvatar = this.newSquad.image_path;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  async saveData() {
    if (this.validateForm() && this.validateImage()) {
      const data = {
        description: this.newSquad.description,
        leader_id: this.newSquad.leader_id,
        image: this.newSquad.image,
        status: this.newSquad.status,
      };
      const statusData = {
        status: this.newSquad.status,
      };

      try {
        this.loadingSave = true;
        if (this.$route.params.id) {
          const response = await this.SquadsRepository.update(
            this.$route.params.id,
            data
          );
          const responseStatus = await this.SquadsRepository.updateStatus(
            this.$route.params.id,
            statusData
          );
          if (localStorage.userTypeId == this.$route.params.id) {
            const currentUser = await this.AuthRepository.getCurrentUser();
            localStorage.users = JSON.stringify(
              JSON.parse(currentUser.data).user.type.users
            );
          }
          if (
            (response.status === 200 || response.status === 201) &&
            (responseStatus.status === 200 || responseStatus.status === 201)
          ) {
            this.$notification.success("Squad editado com sucesso!");
            this.$router.push({ name: this.listRoute });
          }
        } else {
          const response = await this.SquadsRepository.create(data);
          if (response.status === 200 || response.status === 201) {
            this.$notification.success("Squad criado com sucesso!");
            this.$router.push({ name: this.listRoute });
          }
        }
        // eslint-disable-next-line
      } catch (error: any) {
        var message = JSON.parse(error.response.data).errors;
        var textError = "";
        if (message) {
          for (var index in message) {
            var getFirstMessage = message[index];
            textError = `${getFirstMessage[0]}`;
            this.$notification.error(textError);
          }
        }
      } finally {
        this.loadingSave = false;
      }
    }
  }

  async getNewAvatar(file: File) {
    this.newSquad.image = file;
    if (file) {
      if (this.squadAvatar) {
        URL.revokeObjectURL(this.squadAvatar);
      }
      const blob = URL.createObjectURL(file);
      this.squadAvatar = blob;
    }
  }

  async loadUsers() {
    const response = await this.UsersRepository.getAll();
    this.usersItems = this.filterByActiveStatus(
      JSON.parse(response.data).users.data
    );
  }

  filterByActiveStatus(item: Array<IUsers>) {
    return item.filter(function (value) {
      return value.status === "A";
    });
  }

  validateImage() {
    if (!this.$route.params.id) {
      if (this.newSquad.image) {
        return true;
      } else {
        this.$notification.warn("A imagem do squad é obrigatória");
        return false;
      }
    } else {
      return true;
    }
  }
  validateForm() {
    return (
      this.$refs.registerSquadForm as Vue & { validate: () => boolean }
    ).validate();
  }
}
