
import Vue from "vue";
import Component from "vue-class-component";
import { Location } from "vue-router";

import DepartmentsRepository from "@/repositories/DepartmentsRepository";
import IDepartments from "@/domain/interfaces/IDepartments";

@Component
export default class DepartmentsList extends Vue {
  pageTitle = "Departamentos";
  pageIcon = "mdi-laptop";
  pageSubtitle = "Liste e cadastre os departamentos aqui";
  isNoDataText = "Não há departamentos cadastrados";
  listRoute = "departments";
  registerRoute = "departmentsRegister";
  editRoute = "departmentsEdit";
  filterTerm = "";
  filterStatus = "";
  statusItems = [
    { text: "Todos os status", value: "" },
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];
  loadingData = false;
  isLoadingStatus = false;
  readonly DepartmentsRepository: DepartmentsRepository =
    new DepartmentsRepository();
  headers = [
    {
      text: "Status",
      value: "status",
      sortable: false,
      width: "5%",
      align: "start",
    },
    {
      text: "Descrição",
      value: "description",
      sortable: false,
      width: "90%",
      align: "start",
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      width: "5%",
      align: "end",
    },
  ];
  departments: Array<IDepartments> = [];

  mounted() {
    this.getAll();
    this.getPageInfo();
  }

  getPageInfo() {
    if (localStorage.menus != "" && localStorage.menus != "null") {
      var getMenus = JSON.parse(localStorage.menus);
      var getRouteName = this.$route.name;
      var getMenuSelectedByRoute = getMenus.filter(function (value) {
        return value.route === getRouteName;
      });
      if (
        getMenuSelectedByRoute[0].description != "" &&
        getMenuSelectedByRoute[0].description
      ) {
        this.pageTitle = getMenuSelectedByRoute[0].description;
      }
      if (
        getMenuSelectedByRoute[0].icon != "" &&
        getMenuSelectedByRoute[0].icon
      ) {
        this.pageIcon = getMenuSelectedByRoute[0].icon;
      }
      if (
        getMenuSelectedByRoute[0].observation != "" &&
        getMenuSelectedByRoute[0].observation
      ) {
        this.pageSubtitle = getMenuSelectedByRoute[0].observation;
      }
    }
  }

  async getAll() {
    try {
      this.loadingData = true;
      const response = await this.DepartmentsRepository.getAll();
      this.departments = JSON.parse(response.data).departments.data;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  get filteredData() {
    return this.departments
      .filter(this.filterByTerm)
      .filter(this.filterByStatus);
  }

  filterByTerm(departments: IDepartments) {
    const filterTerm = this.filterTerm.toLowerCase();
    return filterTerm != ""
      ? departments.description.toLowerCase().includes(filterTerm)
      : true;
  }

  filterByStatus(departments: IDepartments) {
    const status = this.filterStatus;
    return status != ""
      ? (departments.status === "A") == (this.filterStatus === "A")
      : true;
  }

  async changeStatus(departmentsId: string, status: boolean) {
    try {
      this.loadingData = true;
      this.isLoadingStatus = true;
      var statusData = {
        status: "",
      };
      if (status === true) {
        statusData.status = "A";
      } else {
        statusData.status = "I";
      }
      const responseStatus = await this.DepartmentsRepository.updateStatus(
        departmentsId,
        statusData
      );
      if (responseStatus.status === 200 || responseStatus.status === 201) {
        this.$notification.success("Status alterado com sucesso!");
      }
    } catch (error: any) {
      console.error(JSON.parse(error.response.data));
    } finally {
      this.loadingData = false;
      this.isLoadingStatus = false;
    }
  }

  editItem(departmentsId: string) {
    const params: Location = {
      name: this.editRoute,
      params: {
        id: departmentsId,
      },
    };
    this.$router.push(params);
  }
}
