
import Vue from "vue";
import Component from "vue-class-component";
import { Location } from "vue-router";

import UserTypesRepository from "@/repositories/UserTypesRepository";
import IUserTypes from "@/domain/interfaces/IUserTypes";

@Component
export default class UserTypesList extends Vue {
  pageTitle = "Tipos de Usuários";
  pageIcon = "mdi-account-supervisor-outline";
  pageSubtitle = "Liste e cadastre os tipos de usuários aqui";
  isNoDataText = "Não há tipos de usuários cadastrados";
  listRoute = "user-types";
  registerRoute = "userTypesRegister";
  editRoute = "userTypesEdit";
  filterTerm = "";
  filterStatus = "";
  statusItems = [
    { text: "Todos os status", value: "" },
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
  ];
  loadingData = false;
  isLoadingStatus = false;
  readonly UserTypesRepository: UserTypesRepository = new UserTypesRepository();
  headers = [
    { text: "Status", value: "status", sortable: false },
    { text: "Descrição", value: "description", sortable: false },
    { text: "", value: "actions", align: "end", sortable: false },
  ];
  userTypes: Array<IUserTypes> = [];

  mounted() {
    this.getAll();
    this.getPageInfo();
  }

  getPageInfo() {
    if (localStorage.menus != "" && localStorage.menus != "null") {
      var getMenus = JSON.parse(localStorage.menus);
      var getRouteName = this.$route.name;
      var getMenuSelectedByRoute = getMenus.filter(function (value) {
        return value.route === getRouteName;
      });
      if (
        getMenuSelectedByRoute[0].description != "" &&
        getMenuSelectedByRoute[0].description
      ) {
        this.pageTitle = getMenuSelectedByRoute[0].description;
      }
      if (
        getMenuSelectedByRoute[0].icon != "" &&
        getMenuSelectedByRoute[0].icon
      ) {
        this.pageIcon = getMenuSelectedByRoute[0].icon;
      }
      if (
        getMenuSelectedByRoute[0].observation != "" &&
        getMenuSelectedByRoute[0].observation
      ) {
        this.pageSubtitle = getMenuSelectedByRoute[0].observation;
      }
    }
  }

  async getAll() {
    try {
      this.loadingData = true;
      const response = await this.UserTypesRepository.getAll();
      this.userTypes = JSON.parse(response.data).userTypes.data;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  }

  get filteredData() {
    return this.userTypes.filter(this.filterByTerm).filter(this.filterByStatus);
  }

  filterByTerm(userType: IUserTypes) {
    const filterTerm = this.filterTerm.toLowerCase();
    return filterTerm != ""
      ? userType.description.toLowerCase().includes(filterTerm)
      : true;
  }

  filterByStatus(userType: IUserTypes) {
    const status = this.filterStatus;
    return status != ""
      ? (userType.status === "A") == (this.filterStatus === "A")
      : true;
  }

  async changeStatus(userTypeId: string, status: boolean) {
    try {
      this.loadingData = true;
      this.isLoadingStatus = true;
      var statusData = {
        status: "",
      };
      if (status === true) {
        statusData.status = "A";
      } else {
        statusData.status = "I";
      }
      const responseStatus = await this.UserTypesRepository.updateStatus(
        userTypeId,
        statusData
      );
      if (responseStatus.status === 200 || responseStatus.status === 201) {
        this.$notification.success("Status alterado com sucesso!");
      }
    } catch (error: any) {
      console.error(JSON.parse(error.response.data));
    } finally {
      this.loadingData = false;
      this.isLoadingStatus = false;
    }
  }

  editItem(userTypeId: string) {
    const params: Location = {
      name: this.editRoute,
      params: {
        id: userTypeId,
      },
    };
    this.$router.push(params);
  }
}
