
import Vue from "vue";
import { Prop, Component, Emit } from "vue-property-decorator";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

@Component({
  components: {
    Cropper,
  },
})
export default class MenusList extends Vue {
  @Prop(String) img!: string;

  change() {
    this.convertImageToCropped();
  }

  @Emit()
  getData(data) {
    return data;
  }

  async convertImageToCropped() {
    const result = this.$refs.cropper.getResult();
    const url = result.canvas.toDataURL("image/png");
    await fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], `avatar-image`, {
          type: "image/png",
        });
        this.getData(file);
      });
  }
}
