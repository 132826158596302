
import Vue from "vue";
import Component from "vue-class-component";
import AuthRepository from "@/repositories/AuthRepository";

interface IUser {
  email: string;
  password: string;
}

@Component({})
export default class LoginView extends Vue {
  readonly AuthRepository: AuthRepository = new AuthRepository();
  user: IUser = {
    email: "",
    password: "",
  };
  rules = {
    required: [(value: string) => !!value || "Campo obrigatório."],
    email: [(v: string) => /.+@.+\..+/.test(v) || "Email inválido."],
  };
  log = "";
  passwordValue = true;
  loadLoginButton = false;

  mounted() {
    this.user.email = "";
    this.user.password = "";
  }

  async signUp() {
    if (this.validateForm()) {
      try {
        this.loadLoginButton = true;
        const response = await this.AuthRepository.login(this.user);
        if (response.status === 200) {
          localStorage.token = JSON.parse(response.data).token;
          const responseCurrentUser =
            await this.AuthRepository.getCurrentUser();
          var currentUser = JSON.parse(responseCurrentUser.data).user;
          localStorage.userId = currentUser.id;
          localStorage.userName = currentUser.name;
          localStorage.email = currentUser.email;
          localStorage.status = currentUser.status;
          localStorage.userTypeName = currentUser.type.description;
          localStorage.userTypeDashboard = currentUser.type.dashboard;
          localStorage.userTypeId = currentUser.type.id;
          localStorage.menus = JSON.stringify(currentUser.type.menus);
          this.$router.push({ name: "dashboard" });
          this.$router.go(0);
        }
        // eslint-disable-next-line
      } catch (error: any) {
        if (JSON.parse(error.response.data).errors) {
          this.$notification.error(
            JSON.parse(error.response.data).errors.email[0]
          );
        } else {
          this.$notification.error(JSON.parse(error.response.data).error);
        }
      } finally {
        this.loadLoginButton = false;
      }
    }
  }

  enterKeyPressed(e) {
    if (e.keyCode === 13) {
      this.signUp();
    }
    this.log += e.key;
  }

  validateForm() {
    return (
      this.$refs.authForm as Vue & { validate: () => boolean }
    ).validate();
  }
}
