var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.img,"stencil-props":{
    handlers: {},
    movable: false,
    scalable: false,
    aspectRatio: 1,
    previewClass: 'preview',
  },"stencil-component":"circle-stencil","resize-image":{
    adjustStencil: false,
  },"image-restriction":"stencil"},on:{"change":_vm.change}})
}
var staticRenderFns = []

export { render, staticRenderFns }