/* eslint-disable */
import axios from "axios";
import qs from "qs";
import vue from "@/main";

function getAuthentication() {
  const token = localStorage.token;

  return token || "";
}

function authenticateAllRoutes(config) {
  config.headers.Authorization = `Bearer ${getAuthentication()}`;

  return config;
}

function redirectIfUnauthorized(error) {
  if (error && error.response && error.response.status === 401) {
    localStorage.clear();
    if (vue.$router.currentRoute.name != "login") {
      vue.$router.push({ name: "login" });
    }
  }

  return Promise.reject(error);
}

const Config = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 100000,
  transformResponse: [
    function (data: string) {
      return data;
    },
  ],
  paramsSerializer: (params: any) => qs.stringify(params),
});

Config.interceptors.request.use(authenticateAllRoutes, (error) =>
  Promise.reject(error)
);

Config.interceptors.response.use(
  (response) => response,
  redirectIfUnauthorized
);

export default Config;
