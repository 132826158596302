import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import AppView from "@/views/navigation/AppView.vue";
import DefaultView from "@/components/DefaultView.vue";

import HomeView from "@/views/home/List.vue";

import LoginView from "@/views/login/LoginView.vue";

import DashboardView from "@/views/dashboard/List.vue";

import JobsList from "@/views/jobs/List.vue";
import JobsRegister from "@/views/jobs/Register.vue";

import UserList from "@/views/users/List.vue";
import UserRegister from "@/views/users/Register.vue";

import UserTypesList from "@/views/user-types/List.vue";
import UserTypesRegister from "@/views/user-types/Register.vue";

import MenusList from "@/views/menus/List.vue";
import MenusRegister from "@/views/menus/Register.vue";

import DepartmentsList from "@/views/departments/List.vue";
import DepartmentsRegister from "@/views/departments/Register.vue";

import SquadsList from "@/views/squads/List.vue";
import SquadsRegister from "@/views/squads/Register.vue";

import JobsCandidatesList from "@/views/jobs-candidates/List.vue";

import ProfileView from "@/views/profile/ProfileView.vue";

import AuthRepository from "@/repositories/AuthRepository";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/",
    component: AppView,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: DashboardView,
      },
      {
        path: "/profile",
        name: "profile",
        component: ProfileView,
      },
      {
        path: "/jobs/",
        component: DefaultView,
        children: [
          {
            path: "",
            name: "jobs",
            component: JobsList,
          },
          {
            path: "register",
            name: "jobsRegister",
            component: JobsRegister,
          },
          {
            path: "register/:id",
            name: "jobsEdit",
            component: JobsRegister,
          },
        ],
      },
      {
        path: "/users/",
        component: DefaultView,
        children: [
          {
            path: "",
            name: "users",
            component: UserList,
          },
          {
            path: "register",
            name: "usersRegister",
            component: UserRegister,
          },
          {
            path: "register/:id",
            name: "usersEdit",
            component: UserRegister,
          },
        ],
      },
      {
        path: "/user-types/",
        component: DefaultView,
        children: [
          {
            path: "",
            name: "user-types",
            component: UserTypesList,
          },
          {
            path: "register",
            name: "userTypesRegister",
            component: UserTypesRegister,
          },
          {
            path: "register/:id",
            name: "userTypesEdit",
            component: UserTypesRegister,
          },
        ],
      },
      {
        path: "/menus/",
        component: DefaultView,
        children: [
          {
            path: "",
            name: "menus",
            component: MenusList,
          },
          {
            path: "register",
            name: "menusRegister",
            component: MenusRegister,
          },
          {
            path: "register/:id",
            name: "menusEdit",
            component: MenusRegister,
          },
        ],
      },
      {
        path: "/departments/",
        component: DefaultView,
        children: [
          {
            path: "",
            name: "departments",
            component: DepartmentsList,
          },
          {
            path: "register",
            name: "departmentsRegister",
            component: DepartmentsRegister,
          },
          {
            path: "register/:id",
            name: "departmentsEdit",
            component: DepartmentsRegister,
          },
        ],
      },
      {
        path: "/squads/",
        component: DefaultView,
        children: [
          {
            path: "",
            name: "squads",
            component: SquadsList,
          },
          {
            path: "register",
            name: "squadsRegister",
            component: SquadsRegister,
          },
          {
            path: "register/:id",
            name: "squadsEdit",
            component: SquadsRegister,
          },
        ],
      },
      {
        path: "/jobs-candidates/",
        component: DefaultView,
        children: [
          {
            path: "",
            name: "jobs-candidates",
            component: JobsCandidatesList,
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

const authRepository = new AuthRepository();
router.beforeEach(async (to, from, next) => {
  if (to.name != "login") {
    if (localStorage.token && localStorage.token != "") {
      try {
        authRepository.getCurrentUser();
      } catch (e) {
        console.error(e);
        router.push({ name: "login" });
      }
    } else {
      router.push({ name: "login" });
    }
  }
  next();
});

export default router;
