import Repository from "./base/Repository";

interface INewUserType {
  dashboard: string;
  description: string;
  status: string;
}
interface INewUserTypeStatus {
  status: string;
}
export default class UserTypesRepository extends Repository {
  async getAll() {
    return await this.datasource.get(`/api/user-types`);
  }

  async getById(id: number | string) {
    return await this.datasource.get(`/api/user-types/${id}`);
  }

  async create(data: INewUserType) {
    return await this.datasource.send(`/api/user-types`, data);
  }

  async update(id: number | string, data: INewUserType) {
    return await this.datasource.updatePatch(`/api/user-types/${id}`, data);
  }

  async updateStatus(id: number | string, data: INewUserTypeStatus) {
    return await this.datasource.updatePatch(
      `/api/user-types/${id}/update-status`,
      data
    );
  }
}
