
import Vue from "vue";
import { Prop, Component, Emit, Watch } from "vue-property-decorator";
import ChangeAvatarDialog from "./ChangeAvatarDialog.vue";

@Component({
  components: {
    ChangeAvatarDialog,
  },
})
export default class ChangeAvatarButton extends Vue {
  @Prop(String) avatarPath!: string;
  @Prop(Number) currentUserId?: number;
  @Prop(String) title!: string;
  imageToBeCropped = "";
  imageTypeToBeCropped = "";
  showChangeAvatarDialog = false;

  getFile() {
    this.$refs.fileInput.click();
  }

  onFilePicked(event) {
    const { files } = event.target;
    if (files && files[0]) {
      if (this.imageToBeCropped) {
        URL.revokeObjectURL(this.imageToBeCropped);
      }
      const blob = URL.createObjectURL(files[0]);
      this.imageToBeCropped = blob;
      this.imageTypeToBeCropped = files[0].type;
      this.toggleChangeAvatarDialog();
    }
  }

  toggleChangeAvatarDialog() {
    this.showChangeAvatarDialog = !this.showChangeAvatarDialog;
  }

  getCurrentAvatar(userImg: string) {
    if (userImg && userImg != "") {
      return userImg;
    } else {
      return require("@/assets/img/default-avatar.png");
    }
  }

  getNewAvatarData(value) {
    this.getData(value);
  }

  @Emit()
  getData(value) {
    return value;
  }
}
