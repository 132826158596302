/* eslint-disable */
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Config from "./Config";

export default class DataSource {
  private config: AxiosInstance;

  constructor() {
    this.config = Config;
  }

  public get(
    url = "",
    filters?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    return this.config.get(url, filters);
  }

  public send(
    url: string,
    record: any,
    filters?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    return this.config.post(url, record, filters);
  }

  public update(
    url: string,
    record: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    return this.config.put(url, record, config);
  }

  public updatePatch(
    url: string,
    record: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    return this.config.patch(url, record, config);
  }

  public destroy(url: string, options = {}): Promise<AxiosResponse<any>> {
    return this.config.delete(url, options);
  }

  public upload(url, record) {
    const filters = {
      headers: {
        "Content-type": "multipart/form-data"
      }
    };
    return this.config.post(url, record, filters);
  }

  public uploadPatch(url, record) {
    const filters = {
      headers: {
        "Content-type": "multipart/form-data"
      }
    };
    return this.config.post(`${url}?_method=PATCH`, record, filters);
  }

  public updateUploadPatch(
    url: string,
    record: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> {
    const filters = {
      headers: {
        "Content-type": "multipart/form-data"
      }
    };
    return this.config.patch(url, record, filters);
  }
}
