
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import ICurrentUser from "@/domain/interfaces/ICurrentUser";

@Component({})
export default class AppBar extends Vue {
  @Prop(Boolean) showAppBar!: boolean;
  @Prop(Object) currentUser!: ICurrentUser;
  drawer = false;
  messages = 2;

  notifications = [
    {
      id: 1,
      title: "Título um",
      subtitle:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as",
    },
    {
      id: 2,
      title: "Título dois",
      subtitle: "Subtituloooo",
    },
    {
      id: 3,
      title: "Título três",
      subtitle: "Subtituloooo",
    },
    {
      id: 4,
      title: "Título quatro",
      subtitle: "Subtituloooo",
    },
  ];

  @Emit()
  logOut(routeName) {
    localStorage.clear();
    return routeName;
  }
  @Emit()
  showDrawer() {
    return (this.drawer = true);
  }

  @Emit()
  getRoute(routeName: string) {
    return routeName;
  }

  getImg(item: any) {
    if (item.avatar_path) {
      return item.avatar_path;
    } else if (item.squad && item.squad.image_path != "") {
      return item.squad.image_path;
    } else {
      return require("@/assets/img/default-avatar.png");
    }
  }
}
