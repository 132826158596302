import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins";

Vue.config.productionTip = false;
Vue.prototype.$pageTitle = "";
Vue.prototype.$pageIcon = "";
Vue.prototype.$pageSubtitle = "";

const vue = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

export default vue;
